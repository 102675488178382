import React from "react";
import { chosenSvgTheme } from "../../theme";

function MFeelingProud() {
  const colors = {
    ...chosenSvgTheme,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 573.2 543.75">
      <g id="Слой_1" data-name="Слой 1">
        <g id="freepik--Shadows--inject-2">
          <path
            d="M439.26,407a3.69,3.69,0,0,1-1.3,5l-179.4,103.3a14.69,14.69,0,0,1-14.6,0l-93.7-54A3.31,3.31,0,0,1,149,460a3.6,3.6,0,0,1,1.3-5l8.2-4.7a91.27,91.27,0,0,1-19.7-8.21c-28.8-16.5-28.9-43.5-.4-60.1s74.9-16.5,103.7,0a52.54,52.54,0,0,1,14.4,11.8l80.6-46.4,101,58.3A5.36,5.36,0,0,1,439.26,407Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M448.26,392.23c8.3,4.79,21.7,4.79,30,0s8.4-12.61.1-17.4-21.7-4.8-30,0S440.06,387.43,448.26,392.23Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
        </g>
        <g id="freepik--Plant--inject-2">
          <path
            d="M448.56,381.33a9.6,9.6,0,0,0,4,4.2c5.9,3.5,15.5,3.5,21.5,0a9.88,9.88,0,0,0,4-4.1h0c3.7-6.7,6.4-20.6,5.4-34.2l-20.1.6-20.1-.6C442.36,360.83,445,374.62,448.56,381.33Z"
            fill={colors.white}
          />
          <path
            d="M477.56,339.12c7.8,4.61,7.8,11.9-.1,16.4s-20.6,4.6-28.4,0-7.8-11.9.1-16.4S469.66,334.53,477.56,339.12Z"
            fill={colors.brightGray}
          />
          <path
            d="M474.46,340.93c6.1,3.6,6.1,9.2-.1,12.8s-16.1,3.6-22.2,0-6.1-9.21.1-12.8S468.36,337.33,474.46,340.93Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M474.46,340.93c6.1,3.6,6.1,9.2-.1,12.8s-16.1,3.6-22.2,0-6.1-9.21.1-12.8S468.36,337.33,474.46,340.93Z"
            opacity="0.15"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M440,286.43l14.6,6.1-11.5-.8,3.6,10.8,13.9,7.1-11.9-1.6c6.8,26.9,14.6,24.5,17.1,20.1,10.2-21.6,9.8-51.9-35.5-66.8C434,269.73,437.16,277.93,440,286.43Z"
            fill={colors.highlight}
          />
          <path
            d="M440,286.43l14.6,6.1-11.5-.8,3.6,10.8,13.9,7.1-11.9-1.6c6.8,26.9,14.6,24.5,17.1,20.1,10.2-21.6,9.8-51.9-35.5-66.8C434,269.73,437.16,277.93,440,286.43Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M440,286.43l14.6,6.1-11.5-.8,3.6,10.8,13.9,7.1-11.9-1.6c6.8,26.9,14.6,24.5,17.1,20.1,10.2-21.6,9.8-51.9-35.5-66.8C434,269.73,437.16,277.93,440,286.43Z"
            fill={colors.white}
            opacity={0.2}
            style={{ isolation: "isolate" }}
          />
          <path
            d="M462.36,329.73c6.7-21.71,4.6-42.4-19.3-60.71C467.26,291.23,464.76,310.53,462.36,329.73Z"
            fill={colors.white}
          />
          <path
            d="M493.76,313.93l-14.7,3.4,10.9,1.1-5.2,9.5-14.1,4.2,11.4.6c-10.8,23.9-17.6,20.4-19.3,15.9-5.9-21.8-.5-50,44.1-56.2A246.43,246.43,0,0,0,493.76,313.93Z"
            fill={colors.highlight}
          />
          <path
            d="M493.76,313.93l-14.7,3.4,10.9,1.1-5.2,9.5-14.1,4.2,11.4.6c-10.8,23.9-17.6,20.4-19.3,15.9-5.9-21.8-.5-50,44.1-56.2A246.43,246.43,0,0,0,493.76,313.93Z"
            fill={colors.white}
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M493.76,313.93l-14.7,3.4,10.9,1.1-5.2,9.5-14.1,4.2,11.4.6c-10.8,23.9-17.6,20.4-19.3,15.9-5.9-21.8-.5-50,44.1-56.2A246.43,246.43,0,0,0,493.76,313.93Z"
            opacity="0.1"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M465.76,350.53c-2.6-21.3,2.8-40.3,28.1-53.2C467.56,313.83,466.66,332.33,465.76,350.53Z"
            fill={colors.white}
          />
          <path
            d="M431.46,320.93l13.7,1.3-9.7,2.29,5.8,7.9,13.2,2.3-10.2,1.8c12.4,20.3,18.2,16.4,19.2,12.1,2.9-20.3-5.2-45.1-46.2-45.8A191.84,191.84,0,0,1,431.46,320.93Z"
            fill={colors.highlight}
          />
          <path
            d="M461,350.73c0-19.5-7.1-36.11-31.3-44.8C455.16,317.93,458,334.43,461,350.73Z"
            fill={colors.white}
          />
          <path
            d="M431.46,320.93l13.7,1.3-9.7,2.29,5.8,7.9,13.2,2.3-10.2,1.8c12.4,20.3,18.2,16.4,19.2,12.1,2.9-20.3-5.2-45.1-46.2-45.8A191.84,191.84,0,0,1,431.46,320.93Z"
            fill={colors.white}
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
        </g>
        <g id="freepik--Character--inject-2">
          <path
            d="M218.86,415.83a12.46,12.46,0,0,0-5.5-9.5,2.78,2.78,0,0,1-.7-.4v-4.1l-17.4-7.8a11,11,0,0,0,2.6-7,4.16,4.16,0,0,0-1.6-3.5h0l-3.5-2.2h0v-3.5l-.8-.4-18,3.8v-3.71a3,3,0,0,0,.8-.4,4.12,4.12,0,0,0,2.4-3.39V345h-16.5v28.8a4.27,4.27,0,0,0,2.4,3.4,2.51,2.51,0,0,1,.7.4v4.3l-24.8-1.3-1.8.6v.8a3.9,3.9,0,0,0-2.6.2h0l-3.8,2.3h0a4,4,0,0,0-1.6,3.5,12.3,12.3,0,0,0,5.5,9.5,3.73,3.73,0,0,0,3.8.4h.1l3.5-2.2h0a3.67,3.67,0,0,0,1.7-3.6,9.59,9.59,0,0,0-1.7-5.4l15.2,3.8-28.9,17.1V413a12.16,12.16,0,0,0-4.4,8.8,3.86,3.86,0,0,0,1.7,3.6h0l3.5,2.2h.1a3.68,3.68,0,0,0,3.8-.4,12,12,0,0,0,5.5-9.5,4.16,4.16,0,0,0-1.6-3.5h0l-1.8-1.2,31.1-16.8h0a9.27,9.27,0,0,0,2.4.4h0l3.8,26.9a2.83,2.83,0,0,0-1.8.4h0l-3.5,2.2h0a4,4,0,0,0-1.6,3.5,12.3,12.3,0,0,0,5.5,9.5,3.73,3.73,0,0,0,3.8.4h.1l3.5-2.2h0a3.67,3.67,0,0,0,1.7-3.6,12.23,12.23,0,0,0-4.6-8.9v-3.49l-3-25.8a2.24,2.24,0,0,0,1.2-1.9l32.9,13.3-1,.6h0a4,4,0,0,0-1.6,3.5,12.46,12.46,0,0,0,5.5,9.5,3.73,3.73,0,0,0,3.8.4h0l3.5-2.2h0C218.26,418.62,219,417.33,218.86,415.83Zm-7.7-13-16.9-7.6h0l12.6,5.6Zm-26.6-16.1a8.14,8.14,0,0,0-.8,2.2l-1.8-.8,2.6-1.4Zm-1.1,3.8h0l-9.5-4.2Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M136,393.43l2.9,4.9,3.5-2.3h0a3.67,3.67,0,0,0,1.7-3.6,12.46,12.46,0,0,0-5.5-9.5,4.08,4.08,0,0,0-4-.4h0l-3.6,2.3,2.8,4.7A12.36,12.36,0,0,0,136,393.43Z"
            fill={colors.splash}
          />
          <path
            d="M140.56,394.73c0,3.5-2.5,4.89-5.5,3.1a12,12,0,0,1-5.5-9.5c0-3.5,2.5-4.9,5.5-3.1A12.75,12.75,0,0,1,140.56,394.73Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M191.46,392l-2.9,4.9-3.5-2.2h0a3.69,3.69,0,0,1-1.7-3.61,12.33,12.33,0,0,1,5.5-9.5,4.08,4.08,0,0,1,4-.39h0l3.6,2.29-2.8,4.71A10.27,10.27,0,0,1,191.46,392Z"
            fill={colors.splash}
          />
          <path
            d="M187,393.43c0,3.5,2.5,4.9,5.5,3.1A12.3,12.3,0,0,0,198,387c0-3.5-2.5-4.9-5.5-3.1A12.42,12.42,0,0,0,187,393.43Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M174,394.23h0a2.22,2.22,0,0,1-1.4,2,7.65,7.65,0,0,1-7.2,0,2.42,2.42,0,0,1-1.4-2h0v-17.5h10.2v17.5Z"
            fill={colors.brightGray}
          />
          <polygon
            points="163.96 383.73 163.96 392.43 137.16 385.73 137.16 381.73 163.96 383.73"
            fill={colors.chineseWhite}
          />
          <polygon
            points="163.96 383.73 163.96 382.32 139.06 380.93 137.16 381.73 163.96 383.73"
            fill={colors.cultured}
          />
          <path
            d="M132.36,423.12l-2.9,4.9-3.5-2.29h0a3.69,3.69,0,0,1-1.7-3.61,12.49,12.49,0,0,1,5.5-9.5,4.08,4.08,0,0,1,4-.39h0l3.6,2.29-2.8,4.71A8.65,8.65,0,0,1,132.36,423.12Z"
            fill={colors.splash}
          />
          <path
            d="M127.86,424.62c0,3.5,2.5,4.9,5.5,3.11a12,12,0,0,0,5.5-9.5c0-3.5-2.5-4.9-5.5-3.11A12.06,12.06,0,0,0,127.86,424.62Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M172.16,435.12l2.9,4.9,3.5-2.29h0a3.69,3.69,0,0,0,1.7-3.61,12.33,12.33,0,0,0-5.5-9.5,4.08,4.08,0,0,0-4-.39h0l-3.6,2.29,2.8,4.71A13.19,13.19,0,0,0,172.16,435.12Z"
            fill={colors.splash}
          />
          <path
            d="M176.76,436.62c0,3.5-2.5,4.9-5.5,3.11a12,12,0,0,1-5.5-9.5c0-3.5,2.5-4.9,5.5-3.11A12.27,12.27,0,0,1,176.76,436.62Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M210.66,416.73l2.9,4.89,3.5-2.29h0a3.69,3.69,0,0,0,1.7-3.6,12.46,12.46,0,0,0-5.5-9.5,4.08,4.08,0,0,0-4-.4h0l-3.6,2.29,2.8,4.71A12.47,12.47,0,0,0,210.66,416.73Z"
            fill={colors.splash}
          />
          <path
            d="M215.26,418.12c0,3.5-2.5,4.9-5.5,3.11a12.33,12.33,0,0,1-5.5-9.5c0-3.5,2.5-4.9,5.5-3.11A12.61,12.61,0,0,1,215.26,418.12Z"
            fill={colors.deepSpaceSparkle}
          />
          <polygon
            points="166.56 387.43 166.56 396.82 130.86 416.13 130.86 409.43 166.56 387.43"
            fill={colors.chineseWhite}
          />
          <polygon
            points="130.86 409.43 166.56 387.43 164.36 386.93 128.76 408.23 130.86 409.43"
            fill={colors.cultured}
          />
          <polygon
            points="128.76 408.23 130.86 409.43 130.86 416.13 128.76 414.93 128.76 408.23"
            fill="#f0f0f0"
          />
          <polygon
            points="168.86 397.13 173.26 428.13 173.26 421.93 168.86 387.43 168.86 397.13"
            fill={colors.chineseWhite}
          />
          <rect x="173.26" y="421.93" width="2.5" height="6.1" fill="#f0f0f0" />
          <polygon
            points="173.26 421.93 168.86 387.43 171.86 387.43 175.76 421.93 173.26 421.93"
            fill={colors.cultured}
          />
          <polygon
            points="173.96 386.32 173.96 394.23 211.16 409.43 211.16 402.93 173.96 386.32"
            fill={colors.chineseWhite}
          />
          <polygon
            points="212.56 401.93 211.16 402.93 211.16 409.43 212.56 408.43 212.56 401.93"
            fill="#f0f0f0"
          />
          <polygon
            points="173.96 386.32 173.96 384.52 212.56 401.93 211.16 402.93 206.76 400.82 173.96 386.32"
            fill={colors.cultured}
          />
          <polygon
            points="173.96 382.73 173.96 384.52 182.06 388.13 192.86 382.32 192.86 378.13 173.96 382.73"
            fill={colors.chineseWhite}
          />
          <polygon
            points="173.96 382.73 173.96 381.73 191.96 377.73 192.86 378.13 173.96 382.73"
            fill={colors.cultured}
          />
          <path
            d="M177.26,374.12h0a4.27,4.27,0,0,1-2.4,3.4,12.93,12.93,0,0,1-11.8,0,4.13,4.13,0,0,1-2.4-3.4h0v-28.7h16.7Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M239.26,319.43l-67.9,36a15.7,15.7,0,0,1-14.6.1l-47.5-24.1a24.25,24.25,0,0,1-12.4-15.1l-27.2-100.5a3.34,3.34,0,0,1-2.5-1.2,4.06,4.06,0,0,1-.5-4,8,8,0,0,1,2.5-3.2,29.23,29.23,0,0,1,13.5-7c21.6-10.7,65.6-29.7,65.6-29.7,16.3-6.8,21.3-1.3,24.8,2.3s5,12.4,3.5,24.9-7,44.7,6,57.4c10.4,10.29,49.9-1.3,49.9-1.3h0c7.1-3.8,16.4,1.6,10.3,10.3s-9.4,6.5-9.4,28.7l6.2,3.2a8.13,8.13,0,0,1,3.1,3C246.66,305.23,246.06,315.53,239.26,319.43Z"
            fill={colors.splash}
          />
          <path
            d="M239.26,319.43l-67.9,36a15.7,15.7,0,0,1-14.6.1l-47.5-24.1a24.25,24.25,0,0,1-12.4-15.1l-27.2-100.5h.1a7.89,7.89,0,0,0,3-.5l1.2-.4,22.9,84.69a23.86,23.86,0,0,0,12.4,15.21l47.5,24.1a16,16,0,0,0,14.6-.1l67.9-36a11.63,11.63,0,0,0,3.6-3.4C246.66,305.23,246.06,315.53,239.26,319.43Z"
            fill={colors.splashDark}
          />
          <path
            d="M242.86,299.43a12.93,12.93,0,0,1-3.6,3.4l-67.9,36a15.7,15.7,0,0,1-14.6.1l-1.4-.7h0l1.7-13.3c0-5.6-4-10.8-8.8-13.8s-10.6-4.2-16.1-5.3c-2.2-.5-4.2-.8-6.4-1.3l64.7-33.3,49.2,25.2A9,9,0,0,1,242.86,299.43Z"
            fill={colors.splash}
          />
          <path
            d="M225.26,297.53c-17.6-9.7-51.3-17.9-51.3-17.9-.2-9.6.6-30.2-12.4-43.5,5.3-16.2,3.1-56.7-13.4-65.2,16.3-6.8,21.3-1.3,24.8,2.3s5,12.4,3.5,24.9-7,44.7,6,57.4c10.4,10.3,49.9-1.3,49.9-1.3Z"
            fill={colors.splash}
          />
          <path
            d="M232.46,254.12c7.1-3.79,16.4,1.61,10.3,10.3s-9.4,6.5-9.4,28.7l-8,4.3C222.56,269.73,225.46,258,232.46,254.12Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M154.66,176.43l-70.5,32-1.6-7.9c21.5-10.6,65.7-29.7,65.7-29.7A19.22,19.22,0,0,1,154.66,176.43Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M174.06,279.53l-48.2,24.8-25.5-15.21-16.1-80.7,70.5-32c10.7,14.31,11.5,45.7,7.1,59.6C174.66,249.33,173.76,269.93,174.06,279.53Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M116,255.93c0,1.3,1,1.9,2.2,1.2a5.17,5.17,0,0,0,2.2-3.7c0-1.3-1-1.9-2.2-1.2A4.46,4.46,0,0,0,116,255.93Z"
            fill={colors.splash}
          />
          <path
            d="M126.86,249.53c0,1.3,1,1.9,2.2,1.2a5.17,5.17,0,0,0,2.2-3.7c0-1.3-1-1.9-2.2-1.2A4.33,4.33,0,0,0,126.86,249.53Z"
            fill={colors.splash}
          />
          <path
            d="M137.76,243c0,1.3,1,1.9,2.2,1.2a5.17,5.17,0,0,0,2.2-3.7c0-1.3-1-1.9-2.2-1.2A5,5,0,0,0,137.76,243Z"
            fill={colors.splash}
          />
          <path
            d="M148.66,236.73c0,1.3,1,1.89,2.2,1.19a5.17,5.17,0,0,0,2.2-3.69c0-1.31-1-1.9-2.2-1.2A4.71,4.71,0,0,0,148.66,236.73Z"
            fill={colors.splash}
          />
          <path
            d="M157,324.83l-1.7,13.29-46-23.29c-.6-.4-1.2-.71-1.8-1.1a24.63,24.63,0,0,1-10.6-14.11L74,214.92l-1-3.39,2.4-2.8a22.58,22.58,0,0,1,5.2-1.2c5.3-.7,11.3.6,14.7,4.39s4.3,9.4,4.4,14.5c.2,8.9-.8,17.7-1.3,26.6s-.6,18,2,26.5,7.8,16.5,15.6,20.9a36.24,36.24,0,0,0,9.8,3.6c2.2.5,4.2,1,6.4,1.3,5.5,1.1,11.3,2.4,16.1,5.3C153.16,314,157,319.12,157,324.83Z"
            fill={colors.splashDark}
          />
          <path
            d="M140.26,304.62a6.49,6.49,0,0,0-.6,8,9.62,9.62,0,0,0,4,2.9h0c-15.9-5.2-38.5-12.4-45-29.7-7.9-19.2,1.1-44.1-6-67.3a7.92,7.92,0,0,0-4.8-5.4,14,14,0,0,0-7.6,0c-2.2.5-4.1,1.3-6.1,1.9l-1.2.4a7.89,7.89,0,0,1-3,.5h-.1a3.34,3.34,0,0,1-2.5-1.2,4.06,4.06,0,0,1-.5-4,8,8,0,0,1,2.5-3.2,29.3,29.3,0,0,1,20.1-7.6c7.6.1,14,4.8,15.8,15.6a38.79,38.79,0,0,1,.2,11.3c-3,29-2.6,45.7,3.5,57.8s22.7,12.7,39.4,17.5A8.5,8.5,0,0,0,140.26,304.62Z"
            fill={colors.splash}
          />
          <path
            d="M163.06,333.83l-7.9,4.29v-.7a47.07,47.07,0,0,0-1.4-11.2,15.77,15.77,0,0,0-6.4-9,25.28,25.28,0,0,0-3.7-1.8,9.62,9.62,0,0,1-4-2.9,6.6,6.6,0,0,1,.6-8,8.63,8.63,0,0,1,7.9-2.6,13.27,13.27,0,0,1,7.3,4.3,23,23,0,0,1,4.3,7.6A45.65,45.65,0,0,1,163.06,333.83Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M245.46,312.83s-16.7-11.9-18.6-30l-62.6,17.29c-1.6,8.4-1.9,21.9,2,29.5s14.6,13.11,22.3,16.4Z"
            fill={colors.splashDark}
          />
          <path
            d="M290.66,410.73l1.8,15.29a3.94,3.94,0,0,0,2,1.8h0a3.54,3.54,0,0,0,4.2-2l.1-.2a8.55,8.55,0,0,1,7.7-5.6l3.6-.2s-.2-7.2,0-13.3Z"
            fill="#f6af8c"
          />
          <path
            d="M299.56,445.73a27.67,27.67,0,0,1-8.4-5.4c.1,1.1.2,2,.2,2.79a21.32,21.32,0,0,0,8.2,5.21c8.3,3.5,6.2,10.1,15.6,13.9,9.4,4,23.5,4.7,29.5-4.8v-2.5c-5.9,9.6-20.1,8.8-29.5,4.8S307.76,449.23,299.56,445.73Z"
            fill={colors.splash}
          />
          <path
            d="M291.16,440.33a27.67,27.67,0,0,0,8.4,5.4c8.3,3.5,6.2,10.1,15.6,13.89s23.6,4.71,29.5-4.79v-.5c0-5.3-6.6-9.71-11.9-9.6-7.2-4.61-16.5-14.5-20.3-23.5a2.59,2.59,0,0,0-2.5-1.61l-3.6.21a8.7,8.7,0,0,0-7.7,5.6l-.1.2a3.89,3.89,0,0,1-1.7,1.9,3.22,3.22,0,0,1-4.4-1.6l-.4-2.9-.7.7a3.41,3.41,0,0,0-.5,2.4,12.75,12.75,0,0,1,0,5.5A41.75,41.75,0,0,0,291.16,440.33Z"
            fill={colors.highlight}
          />
          <path
            d="M320.86,434.23c-.5-.61-1.1-1.21-1.6-1.8a13.23,13.23,0,0,0-12.1,5.4,1.17,1.17,0,0,0,.1,1.29,1.16,1.16,0,0,0,1.7.11A13.11,13.11,0,0,1,320.86,434.23Z"
            fill={colors.white}
          />
          <path
            d="M317.66,430.12a22.68,22.68,0,0,1-1.4-2,14.24,14.24,0,0,0-12.7,5.5,1.19,1.19,0,0,0,.1,1.3,1.16,1.16,0,0,0,1.7.1C309,430.62,313.06,429.73,317.66,430.12Z"
            fill={colors.white}
          />
          <path
            d="M324.66,438.12l-1.8-1.79a13.55,13.55,0,0,0-11.9,5.4,1.17,1.17,0,0,0,.1,1.29,1.16,1.16,0,0,0,1.7.1A13.81,13.81,0,0,1,324.66,438.12Z"
            fill={colors.white}
          />
          <path
            d="M315.46,459.73c9.4,3.79,23.3,4.39,29-4.9v-.5c0-5.3-6.6-9.71-11.9-9.6C328.56,444.62,316.16,447,315.46,459.73Z"
            fill={colors.white}
          />
          <path
            d="M329.06,442.12c-.7-.6-1.4-1.2-2.3-1.89a13.57,13.57,0,0,0-11.9,5.39,1.19,1.19,0,0,0,.1,1.3,1.16,1.16,0,0,0,1.7.1C320.36,442.62,324.46,441.73,329.06,442.12Z"
            fill={colors.white}
          />
          <path
            d="M305,370.43l9.6,46.2c-8.4,8.3-29.6,3.7-29.6,3.7-5.5-16.9-7.6-24.9-10.6-37.5Z"
            fill={colors.splashDark}
          />
          <path
            d="M228.16,422.53l1.8,15.3a3.92,3.92,0,0,0,2,1.79h0a3.48,3.48,0,0,0,4.2-2l.1-.2a8.55,8.55,0,0,1,7.7-5.6l3.6-.2s-.2-7.2,0-13.3Z"
            fill="#f6af8c"
          />
          <path
            d="M237.06,457.43a27.48,27.48,0,0,1-8.4-5.4c.1,1.1.2,2,.2,2.8a21.41,21.41,0,0,0,8.2,5.2c8.3,3.5,6.2,10.1,15.6,13.9s23.6,4.7,29.5-4.8v-2.6c-5.9,9.6-20.1,8.8-29.5,4.8C243.46,467.43,245.46,460.93,237.06,457.43Z"
            fill={colors.splash}
          />
          <path
            d="M228.76,452.23a27.45,27.45,0,0,0,8.4,5.39c8.3,3.5,6.2,10.11,15.6,13.9s23.5,4.71,29.5-4.79v-.61c0-5.29-6.6-9.7-11.9-9.6-7.2-4.6-16.5-14.5-20.3-23.5a2.38,2.38,0,0,0-2.5-1.6l-3.6.2a8.71,8.71,0,0,0-7.7,5.61l-.1.2a3.36,3.36,0,0,1-6.2.2l-.4-2.9-.7.7a3.41,3.41,0,0,0-.5,2.4,12.75,12.75,0,0,1,0,5.5A43.59,43.59,0,0,0,228.76,452.23Z"
            fill={colors.highlight}
          />
          <path
            d="M258.56,445.93c-.5-.6-1-1.2-1.6-1.8a13.23,13.23,0,0,0-12.1,5.4,1.19,1.19,0,0,0,.1,1.3,1.16,1.16,0,0,0,1.7.1C250.26,446.62,254.16,445.73,258.56,445.93Z"
            fill={colors.white}
          />
          <path
            d="M255.26,441.83c-.5-.71-1-1.3-1.4-2a14.22,14.22,0,0,0-12.7,5.5,1.17,1.17,0,0,0,.1,1.29,1.16,1.16,0,0,0,1.7.11C246.66,442.43,250.76,441.62,255.26,441.83Z"
            fill={colors.white}
          />
          <path
            d="M262.36,450l-1.8-1.8a13.55,13.55,0,0,0-11.9,5.4,1.19,1.19,0,0,0,.1,1.3,1.16,1.16,0,0,0,1.7.1A12.88,12.88,0,0,1,262.36,450Z"
            fill={colors.white}
          />
          <path
            d="M253.16,471.62c9.4,3.8,23.3,4.4,29-4.89v-.61c0-5.29-6.6-9.7-11.9-9.6C266.16,456.53,253.86,458.83,253.16,471.62Z"
            fill={colors.white}
          />
          <path
            d="M266.66,453.83c-.7-.6-1.4-1.21-2.3-1.9a13.55,13.55,0,0,0-11.9,5.4,1.38,1.38,0,0,0,.2,1.7,1.24,1.24,0,0,0,1.7-.1C258.06,454.33,262.16,453.53,266.66,453.83Z"
            fill={colors.white}
          />
          <path
            d="M254.76,397.12l-1.6,30.8c-9.7,6.7-27.7.7-27.7.7-2.5-17.6-2-29.29-2.9-42.2Z"
            fill={colors.splashDark}
          />
          <path
            d="M150.46,335.83v10.79l4,2.3h0l-4-2.3V458.83l4,2.29,4-2.29v-75.4l89,51.3v80l3.8,2.3,4-2.3V402.53L407,315l24,13.8v80l4,2.29,4-2.29V296.53h0v-10.4l-100.8-58.3-187.7,108ZM430.86,320l-16.3-9.5,16.3-9.5Zm-272.5,31.3,89,51.29V426l-89-51.4V351.33Z"
            fill={colors.splashDark}
          />
          <polygon
            points="430.86 296.52 430.86 408.82 434.76 411.02 434.76 298.82 430.86 296.52"
            fill={colors.splashDark}
          />
          <polygon
            points="438.76 296.52 438.76 408.82 434.76 411.02 434.76 298.82 438.76 296.52"
            fill={colors.splash}
          />
          <polygon
            points="430.86 324.52 337.86 270.93 337.86 275.23 430.86 328.82 430.86 324.52"
            fill={colors.splashDark}
          />
          <polygon
            points="430.86 324.52 337.86 270.93 341.86 268.63 430.86 320.02 430.86 324.52"
            fill={colors.deepSpaceSparkle}
          />
          <polygon
            points="150.46 346.63 150.46 458.93 154.46 461.32 154.46 348.93 150.46 346.63"
            fill={colors.splashDark}
          />
          <polygon
            points="158.36 346.63 158.36 458.93 154.46 461.32 154.46 348.93 158.36 346.63"
            fill={colors.splash}
          />
          <polygon
            points="247.26 402.63 247.26 514.92 251.36 517.13 251.36 404.93 247.26 402.63"
            fill={colors.splashDark}
          />
          <polygon
            points="255.26 402.63 255.26 514.92 251.36 517.13 251.36 404.93 255.26 402.63"
            fill={colors.splash}
          />
          <polygon
            points="247.26 430.63 154.46 377.02 154.46 381.23 247.26 434.93 247.26 430.63"
            fill={colors.splashDark}
          />
          <polygon
            points="247.26 430.63 154.46 377.02 158.36 374.73 247.26 426.02 247.26 430.63"
            fill={colors.deepSpaceSparkle}
          />
          <polygon
            points="150.46 335.82 337.86 227.82 438.76 286.13 251.36 394.13 150.46 335.82"
            fill={colors.deepSpaceSparkle}
          />
          <polygon
            points="251.36 394.13 438.76 286.13 438.76 296.52 251.36 404.93 251.36 394.13"
            fill={colors.splash}
          />
          <polygon
            points="251.36 404.93 150.46 346.63 150.46 335.82 251.36 394.13 251.36 404.93"
            fill={colors.splashDark}
          />
          <polygon
            points="367.76 247.53 382.76 280.32 343.56 286.32 328.56 253.53 367.76 247.53"
            fill="#e6e6e6"
          />
          <polygon
            points="346.86 246.32 380.86 274.02 347.76 287.52 313.76 259.82 346.86 246.32"
            fill="#f0f0f0"
          />
          <polygon
            points="357.66 267.63 337.26 288.02 286.46 271.02 306.86 250.63 357.66 267.63"
            fill={colors.white}
          />
          <path
            d="M417.56,287.23l-28.7,16.5-36.2-20.9,28.8-16.5a2.27,2.27,0,0,1,2.5,0l33.7,19.4a.8.8,0,0,1,.2,1.2C417.76,287,417.66,287.23,417.56,287.23Z"
            fill={colors.highlight}
          />
          <path
            d="M417.56,287.23l-28.7,16.5-36.2-20.9,28.8-16.5a2.27,2.27,0,0,1,2.5,0l33.7,19.4a.8.8,0,0,1,.2,1.2C417.76,287,417.66,287.23,417.56,287.23Z"
            opacity="0.7"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M415.46,286.62l-26.7,15.5-33.7-19.39,26.7-15.5a2.35,2.35,0,0,1,2.3,0l31.3,18.1a.71.71,0,0,1,.2,1.1C415.76,286.43,415.56,286.62,415.46,286.62Z"
            fill={colors.white}
          />
          <polygon
            points="388.76 302.02 388.76 299.02 415.86 283.32 415.86 286.02 388.76 302.02"
            fill={colors.white}
          />
          <polygon
            points="388.76 302.02 388.76 299.02 355.06 279.52 355.06 282.63 388.76 302.02"
            fill={colors.chineseWhite}
          />
          <path
            d="M417.56,282.53,388.86,299l-36.2-20.9,28.8-16.5a2.27,2.27,0,0,1,2.5,0l33.7,19.5a.82.82,0,0,1,.2,1.2C417.66,282.33,417.56,282.43,417.56,282.53Z"
            fill={colors.highlight}
          />
          <path
            d="M417.56,282.53,388.86,299l-36.2-20.9,28.8-16.5a2.27,2.27,0,0,1,2.5,0l33.7,19.5a.82.82,0,0,1,.2,1.2C417.66,282.33,417.56,282.43,417.56,282.53Z"
            opacity="0.5"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M385.06,301.62a.42.42,0,0,1-.5,0,.8.8,0,0,1-.4-.6v-2.79a3.48,3.48,0,0,1,1.8-3l.1-.11c.1-.1.4,0,.5.11v.1a.6.6,0,0,0,1.2,0,1.24,1.24,0,0,0-.7-1.21,1.7,1.7,0,0,0-1.4,0h-.1a4.75,4.75,0,0,0-2.3,4.11V301a2,2,0,0,0,1,1.6,2.16,2.16,0,0,0,.8.21,2.73,2.73,0,0,0,1-.21.52.52,0,0,0,.2-.39Z"
            fill={colors.white}
          />
          <path
            d="M379.66,298.62a.42.42,0,0,1-.5,0,.8.8,0,0,1-.4-.6v-2.79a3.48,3.48,0,0,1,1.8-3h.5c.1,0,.1.1.1.2a.6.6,0,1,0,1.2,0,1.21,1.21,0,0,0-.7-1.2,1.63,1.63,0,0,0-1.4,0h-.1a4.72,4.72,0,0,0-2.3,4.1v2.79a2,2,0,0,0,1,1.61,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,1-.2.55.55,0,0,0,.2-.4Z"
            fill={colors.white}
          />
          <path
            d="M374.36,295.43a.45.45,0,0,1-.5,0,.82.82,0,0,1-.4-.6V292a3.49,3.49,0,0,1,1.8-3h.5c.1,0,.1.1.1.2a.6.6,0,1,0,1.2,0,1.21,1.21,0,0,0-.7-1.2,1.7,1.7,0,0,0-1.4,0h-.1a4.74,4.74,0,0,0-2.3,4.1v2.8a2,2,0,0,0,1,1.6,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,1-.2.52.52,0,0,0,.2-.4Z"
            fill={colors.white}
          />
          <path
            d="M369,292.33a.45.45,0,0,1-.5,0,.82.82,0,0,1-.4-.6v-2.8a3.49,3.49,0,0,1,1.8-3h.5c.1,0,.1.1.1.2a.6.6,0,0,0,1.2,0,1.21,1.21,0,0,0-.7-1.2,1.7,1.7,0,0,0-1.4,0h-.1a4.74,4.74,0,0,0-2.3,4.1v2.8a2,2,0,0,0,1,1.6,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,1-.2.52.52,0,0,0,.2-.4Z"
            fill={colors.white}
          />
          <path
            d="M363.56,289.23a.45.45,0,0,1-.5,0,.84.84,0,0,1-.4-.61v-2.79a3.48,3.48,0,0,1,1.8-3h.5c.1,0,.1.1.1.2a.6.6,0,0,0,1.2,0,1.21,1.21,0,0,0-.7-1.2,1.63,1.63,0,0,0-1.4,0h-.1a4.73,4.73,0,0,0-2.3,4.1v2.8a2,2,0,0,0,1,1.6,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,1-.2.54.54,0,0,0,.2-.4Z"
            fill={colors.white}
          />
          <path
            d="M358.26,286.12a.42.42,0,0,1-.5,0,.8.8,0,0,1-.4-.6v-2.79a3.48,3.48,0,0,1,1.8-3h.5c.1,0,.1.1.1.2a.6.6,0,0,0,1.2,0,1.48,1.48,0,0,0-1.6-1.4,1.07,1.07,0,0,0-.6.2l-.1.1a4.74,4.74,0,0,0-2.3,4.1v2.8a2,2,0,0,0,1,1.6,2.35,2.35,0,0,0,.8.2,3,3,0,0,0,1-.2.52.52,0,0,0,.2-.4Z"
            fill={colors.white}
          />
          <path
            d="M338.66,308.62v3c0,.11-.1.21-.2.4s-.1.1-.1.21l-.4.39-.2.21c-.2.2-.5.4-.7.6l-67.9,39.19a4.2,4.2,0,0,1-1,.5h-.1a2.16,2.16,0,0,1-.8.21h-.1a3.4,3.4,0,0,1-1,.1h-2.3l-.7-.1c-.2,0-.5-.1-.6-.1a1.88,1.88,0,0,0-.5-.11c-.4-.1-.8-.39-1.2-.5l-41.1-23.89a3.82,3.82,0,0,1-.7-.61h0a1.41,1.41,0,0,1-.5-.6h0a1.42,1.42,0,0,1-.2-.6h0v-.6h0v-2.6h0a2.71,2.71,0,0,1,1.8-2.3l67.9-39.2a9.53,9.53,0,0,1,8.4,0l41.1,23.9A3.88,3.88,0,0,1,338.66,308.62Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M218,323.73v2.6h0v.6h0c.1.2.1.4.2.6h0c.1.2.4.4.5.6h0c.2.2.5.4.7.6l41.1,23.9a6.12,6.12,0,0,0,1.2.5,2,2,0,0,0,.5.1c.3,0,.4.1.6.1l.7.1h2.3c.4,0,.6-.1,1-.1h.1c.2-.1.6-.1.8-.2h.1a2.35,2.35,0,0,0,1-.5l67.9-39.2a3.74,3.74,0,0,0,.7-.6l.2-.21.4-.39c0-.11.1-.11.1-.21s.1-.2.2-.4v-2.39c0,.1-.1.2-.2.39s-.1.11-.1.21l-.4.4-.2.2c-.2.2-.5.4-.7.6l-68,39.2a4.61,4.61,0,0,1-1,.5h-.1a2.35,2.35,0,0,1-.8.2h-.1a3.4,3.4,0,0,1-1,.1h-2.3l-.7-.1-.6-.1-.5-.1c-.4-.1-.8-.4-1.2-.5l-41.1-23.9a3.74,3.74,0,0,1-.7-.6h0a1.5,1.5,0,0,1-.5-.61V325a1.07,1.07,0,0,1-.2-.6h0a1.7,1.7,0,0,1-.1-.7Z"
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M309.76,295l-68.1,39.2,21.2,12.4c.1.1.2.1.5.2l.5.1.5.1H266l.4-.1.5-.1h.1l.2-.1,65.9-38.1Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M268.36,298.12l-18.6,10.71a1.72,1.72,0,0,0-.6.6,1.3,1.3,0,0,0,.6,1.6l12.4,7.3a1.82,1.82,0,0,0,2,0l18.6-10.71a1.64,1.64,0,0,0,.6-.6,1.3,1.3,0,0,0-.6-1.6l-12.4-7.3A1.85,1.85,0,0,0,268.36,298.12Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M309.76,295l-68.1,39.2,21.2,12.4c.1.1.2.1.5.2l.5.1.5.1H266l.4-.1.5-.1h.1l.2-.1,65.9-38.1Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M268.36,298.12l-18.6,10.71a1.72,1.72,0,0,0-.6.6,1.3,1.3,0,0,0,.6,1.6l12.4,7.3a1.82,1.82,0,0,0,2,0l18.6-10.71a1.64,1.64,0,0,0,.6-.6,1.3,1.3,0,0,0-.6-1.6l-12.4-7.3A1.85,1.85,0,0,0,268.36,298.12Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M266.76,288.83c5,2.5,16.9-7.9,11.9-15.1C268.36,268.53,261.76,286.33,266.76,288.83Z"
            fill={colors.highlight}
          />
          <path
            d="M266.76,288.83c5,2.5,16.9-7.9,11.9-15.1C268.36,268.53,261.76,286.33,266.76,288.83Z"
            opacity="0.4"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M215.46,196.53l-8-1,20.4,62.59a25.54,25.54,0,0,0,12.9,15l35.7,17.71c6.7,10.79,14.3,13.1,14.3,13.1h0l.4-.4a3.14,3.14,0,0,0-.4-4.6l-3-2.6v-2.9l11.2-1.2,5.4,2.79a7.52,7.52,0,0,0,7.9-.6l2.2-1.6.1-.1a2.66,2.66,0,0,0,.4-3.6l-3.5-4.1a7.35,7.35,0,0,0-1.9-1.6l-7.1-4.2a7.16,7.16,0,0,0-4.1-1l-12.2,1a12.89,12.89,0,0,1-6.1-1l-30.3-23.89-13.7-40.8A24.87,24.87,0,0,0,215.46,196.53Z"
            fill="#f6af8c"
          />
          <path
            d="M203.86,192.63l21.6,66.4a28,28,0,0,0,14.1,16.4l19.3,9.6a19,19,0,0,1-1.1-3.6,13.31,13.31,0,0,1,11.3-15.1L252,252.93l-13.4-40a27.51,27.51,0,0,0-22.7-18.6Z"
            fill={colors.highlight}
          />
          <path
            d="M258.86,284.93l7.9,3.8a13.22,13.22,0,0,1-.4-3.4,11.8,11.8,0,0,1,12.4-11.6l-9.6-7.61a13.59,13.59,0,0,0-10.3,18.81Z"
            fill={colors.highlight}
          />
          <path
            d="M203.86,192.63l21.6,66.4a28,28,0,0,0,14.1,16.4l19.3,9.6a19,19,0,0,1-1.1-3.6,13.31,13.31,0,0,1,11.3-15.1L252,252.93l-13.4-40a27.51,27.51,0,0,0-22.7-18.6Z"
            fill={colors.white}
            opacity="0.4"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M258.86,284.93l7.9,3.8a13.22,13.22,0,0,1-.4-3.4,11.8,11.8,0,0,1,12.4-11.6l-9.6-7.61a13.59,13.59,0,0,0-10.3,18.81Z"
            fill={colors.white}
            opacity="0.7"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M251,249.83s-13.2-3-12.8,9.79c1.1-2.29,5.2-6.7,13.8-6.79Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M349.26,260.33l-7.1,44.7a8.75,8.75,0,0,1-4,5.9l-68.3,39.7a1.67,1.67,0,0,1-1.8.1h0l-1.6-.7h0a2.14,2.14,0,0,1-1.2-2.2l7.1-44.71a8.41,8.41,0,0,1,4-5.89l68.3-39.71a1.68,1.68,0,0,1,1.8-.1h0l1.7.8h0A2.1,2.1,0,0,1,349.26,260.33Z"
            fill={colors.chineseWhite}
          />
          <path
            d="M316.06,301.93a14.24,14.24,0,0,1-6.8,9.7c-3.4,1.9-5.6.6-5.2-2.9a14.24,14.24,0,0,1,6.8-9.7C314.16,297.23,316.56,298.43,316.06,301.93Z"
            fill={colors.white}
          />
          <path
            d="M268.16,350.73h0l-1.6-.71h0a2.14,2.14,0,0,1-1.2-2.2l7-44.7a8.43,8.43,0,0,1,4-5.9l68.3-39.7a1.68,1.68,0,0,1,1.8-.1h0l1.7.8h0a1.82,1.82,0,0,0-2,0l-68.3,39.7a8,8,0,0,0-4,5.9l-7,44.7A1.76,1.76,0,0,0,268.16,350.73Z"
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M162.46,288.62l-1.3,10.9c13.4,7.3,54.8,10.21,71.8-2.29V286.52Z"
            fill={colors.highlight}
          />
          <path
            d="M162.46,288.62l-1.3,10.9c13.4,7.3,54.8,10.21,71.8-2.29V286.52Z"
            fill={colors.white}
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M161.06,299.53c1.4.7,2.9,1.3,4.3,1.9l.2-1.9c0-.2.1-.4.1-.6a4.1,4.1,0,0,1,4.9-2.8,100.08,100.08,0,0,0,46.8,1.6c5.5-1.2,11.2-3,15.5-6.6v-4.4l-70.4,1.9Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M187.16,193.53l-22.8,8.5a44.67,44.67,0,0,0-4.8,22.39c.6,8.61,1.2,22.5,3.6,28.2,1.1,2.5-4.6,27.3-4.6,27.3a3,3,0,0,1-.4.8A8.94,8.94,0,0,0,164,292c14.9,4.9,43.6,8.4,63.8.5a13.23,13.23,0,0,0,7.7-16.2c-2.8-9.5-6.1-23.9-4.8-35.9,1.7-15.8-1.2-30.5-7.4-39.9a14.45,14.45,0,0,0-10.8-6.5l-6-.6Z"
            fill={colors.highlight}
          />
          <path
            d="M187.16,193.53l-22.8,8.5a44.67,44.67,0,0,0-4.8,22.39c.6,8.61,1.2,22.5,3.6,28.2,1.1,2.5-4.6,27.3-4.6,27.3a3,3,0,0,1-.4.8A8.94,8.94,0,0,0,164,292c14.9,4.9,43.6,8.4,63.8.5a13.23,13.23,0,0,0,7.7-16.2c-2.8-9.5-6.1-23.9-4.8-35.9,1.7-15.8-1.2-30.5-7.4-39.9a14.45,14.45,0,0,0-10.8-6.5l-6-.6Z"
            fill={colors.white}
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M158.66,280a4.18,4.18,0,0,1-.4,1,8.91,8.91,0,0,0,5.9,11.3,46.26,46.26,0,0,0,4.8,1.4,10,10,0,0,1-3.6-6.71l-.7-7.29a38.63,38.63,0,0,1,0-9.61l.1-.6a197.53,197.53,0,0,1,4.8-26.4,72.06,72.06,0,0,0,2.2-20.1l-11.9-4.7a45.82,45.82,0,0,0-.1,6.4c.6,8.6,1.2,22.5,3.6,28.2C164.16,255.23,158.66,280,158.66,280Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M228.46,276.62v10.11c-5,2.89-19.2,8.79-49.2,4.2v-13.3c2,0,9.7-4.4,9.6-12.8,0,0,18.6,2.2,32.5-2C221.46,262.73,222.26,274.83,228.46,276.62Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M226.16,207.33a3.6,3.6,0,0,0,1.2-3,10.08,10.08,0,0,0-4.7-8.2,3.75,3.75,0,0,0-3.4-.4l.4.2A15.65,15.65,0,0,1,226.16,207.33Z"
            fill={colors.highlight}
          />
          <path
            d="M226.16,207.33a3.6,3.6,0,0,0,1.2-3,10.08,10.08,0,0,0-4.7-8.2,3.75,3.75,0,0,0-3.4-.4l.4.2A15.65,15.65,0,0,1,226.16,207.33Z"
            fill={colors.white}
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M220.76,213.43h0l3.4-1.9h0a4.38,4.38,0,0,0,1.9-4.2h0a14.11,14.11,0,0,0-6.5-11.3l-.4-.2a4.15,4.15,0,0,0-4.3-.2h0l-3.4,1.9h0a4.49,4.49,0,0,1,4.6.5,14.65,14.65,0,0,1,6.5,11.3A4.35,4.35,0,0,1,220.76,213.43Z"
            fill={colors.highlight}
          />
          <path
            d="M220.76,213.43h0l3.4-1.9h0a4.38,4.38,0,0,0,1.9-4.2h0a14.11,14.11,0,0,0-6.5-11.3l-.4-.2a4.15,4.15,0,0,0-4.3-.2h0l-3.4,1.9h0a4.49,4.49,0,0,1,4.6.5,14.65,14.65,0,0,1,6.5,11.3A4.35,4.35,0,0,1,220.76,213.43Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M209.56,201.73a14.11,14.11,0,0,0,6.5,11.3,4.31,4.31,0,0,0,3.4,1,3.86,3.86,0,0,0,3.1-4.7,14.11,14.11,0,0,0-6.5-11.3,4.49,4.49,0,0,0-4.6-.5A4.59,4.59,0,0,0,209.56,201.73Zm2.9,1.7c0-2.3,1.7-3.2,3.7-2a7.75,7.75,0,0,1,3.6,6.4c0,2.3-1.7,3.4-3.7,2.2A9,9,0,0,1,212.46,203.43Z"
            fill={colors.highlight}
          />
          <path
            d="M209.56,201.73a14.11,14.11,0,0,0,6.5,11.3,4.31,4.31,0,0,0,3.4,1,3.86,3.86,0,0,0,3.1-4.7,14.11,14.11,0,0,0-6.5-11.3,4.49,4.49,0,0,0-4.6-.5A4.59,4.59,0,0,0,209.56,201.73Zm2.9,1.7c0-2.3,1.7-3.2,3.7-2a7.75,7.75,0,0,1,3.6,6.4c0,2.3-1.7,3.4-3.7,2.2A9,9,0,0,1,212.46,203.43Z"
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M212.46,203.43a8,8,0,0,0,3.6,6.4c2,1.2,3.7.2,3.7-2.2a8.18,8.18,0,0,0-3.6-6.4C214.16,200.13,212.46,201.13,212.46,203.43Z"
            fill={colors.highlight}
          />
          <path
            d="M212.46,203.43a8,8,0,0,0,3.6,6.4c2,1.2,3.7.2,3.7-2.2a8.18,8.18,0,0,0-3.6-6.4C214.16,200.13,212.46,201.13,212.46,203.43Z"
            opacity="0.5"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M217.16,241.83a40.38,40.38,0,0,1-4.9-22.1,37.47,37.47,0,0,1,.5-4.81,47.26,47.26,0,0,0,.5-5.3,19.67,19.67,0,0,0-1.8-9.5c-.1-.1-.1-.1-.1-.2a.67.67,0,0,1,1.3-.3,18.93,18.93,0,0,1,2,10.2,47.08,47.08,0,0,1-.5,5.4c-.2,1.6-.4,3.1-.5,4.7a39.18,39.18,0,0,0,4.8,21.2.78.78,0,0,1-.2,1c-.1,0-.2.1-.4.1A.65.65,0,0,1,217.16,241.83Z"
            fill={colors.white}
          />
          <path
            d="M227.26,153.63l-11.5-4.4c3.1,11-1.8,42.1-11.4,55.4C218.56,203.43,232.86,183,227.26,153.63Z"
            fill={colors.highlight}
          />
          <path
            d="M230.16,157.33a19.42,19.42,0,0,0,.2-2.4,15.23,15.23,0,0,0-2.2-7.7l-32.8-16.6s-17.9-2.2-19.9,22.3a82.65,82.65,0,0,0,4.1,22.5c1.2,3.7,3,7.8,6.6,9.5,0,0,16.7,11.6,20.1,14.9s9.8,5.9,9.8,5.9-4.6-4.9-3.7-8c0,0,5.5,6.5,9,8a24.12,24.12,0,0,1-2.6-7.5,33.65,33.65,0,0,0,3,1.89s-4.1-5.8-2.9-9.1c0,0,4.9,7.6,8.3,9.6a30.69,30.69,0,0,1-1.9-13.3l.1-.8a48.8,48.8,0,0,0,3.5,8.7c-1.4-5,1.1-30.2,1.1-30.2Z"
            fill={colors.hair}
          />
          <path
            d="M206,178.43v14.7a7.21,7.21,0,0,0,1.2,4,8.17,8.17,0,0,1,1.1,2.3,4.13,4.13,0,0,1-1,4.2,6,6,0,0,1-3.1,1,20.37,20.37,0,0,1-14.9-5,7.29,7.29,0,0,1-2.5-5.5v-15.6l19.2-.1Z"
            fill="#f6af8c"
          />
          <path
            d="M188.46,184.43a18.65,18.65,0,0,1-1.6-2.8v-3.3H206v14.1a36.06,36.06,0,0,1-7.8-1.6A17.92,17.92,0,0,1,188.46,184.43Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M196.36,147.63c-.1-.1-.4-.1-.5-.1a1.56,1.56,0,0,0-1.7,1.39,20,20,0,0,1-.8,4,6.81,6.81,0,0,1-2.9,3.4,2.51,2.51,0,0,0-1.4,2.4l.2,6.5a3.12,3.12,0,0,1-.7,2.5,2.11,2.11,0,0,1-2.4,0,5.81,5.81,0,0,1-1.7-1.8,16.51,16.51,0,0,0-3.5-4,5.78,5.78,0,0,0-4.9-1.4c-2.9.6-4.4,3.7-4.3,6.6a8.38,8.38,0,0,0,13,6.61c-.5,5.19,3,10,7.4,12.6s9.6,3.6,14.6,4.4c2.8.5,5.8,1,8.4,0a10.28,10.28,0,0,0,5-4.4,29.32,29.32,0,0,0,2.5-6.4,81.34,81.34,0,0,0,2-31.1A45.79,45.79,0,0,1,196.36,147.63Z"
            fill="#f6af8c"
          />
          <path
            d="M181.56,163.53l11.8-18.9s3.9,33.1,6.3,37.8a51,51,0,0,1-4.8-7.2,28.74,28.74,0,0,0,3.8,10.5,25.91,25.91,0,0,1-2.9-1.4l1.3,3a29.29,29.29,0,0,0,7.9,10.8c-3.9-.2-11.8-4.6-11.8-4.6.4,3.6,6.8,6.7,6.8,6.7s-6.9-.2-11.5-2.2-5-10.1-5-10.1Z"
            fill={colors.hair}
          />
          <path
            d="M230.36,154.93v.1c-19.8-9.4-45.2,4.2-45.2,4.2l.6-9.4c18.1-5.9,34.2-5.6,40.8-3.7h0a10.48,10.48,0,0,1,2.2.8,1.9,1.9,0,0,1,.8.7C231.56,150.23,230.56,154.23,230.36,154.93Z"
            fill={colors.splashDark}
          />
          <path
            d="M228.46,146.93h-1.9l-40.9,3.1s-1.4-14.1,9.8-18.9a22.9,22.9,0,0,1,9.7-1.6h0C227,130.33,228.46,146.93,228.46,146.93Z"
            fill={colors.splashDark}
          />
          <path
            d="M205.46,178.73l6,2.19a3.29,3.29,0,0,1-4.2,1.9,3.17,3.17,0,0,1-1.8-4.09Z"
            fill="#b16668"
          />
          <path
            d="M209,182.93a3.78,3.78,0,0,0-3.7-3.4,3,3,0,0,0,2,3.2A2,2,0,0,0,209,182.93Z"
            fill="#f28f8f"
          />
          <path
            d="M207,168.13a1.8,1.8,0,0,1-3.6-.2,1.86,1.86,0,0,1,1.9-1.7,1.8,1.8,0,0,1,1.7,1.9Z"
            fill={colors.splashDark}
          />
          <path
            d="M206.46,159.63l-4.3,1.2a2.31,2.31,0,0,1,1.6-2.8,2.22,2.22,0,0,1,2.7,1.6Z"
            fill={colors.splashDark}
          />
          <path
            d="M223,160.63l-4.5-.8a2.15,2.15,0,0,1,2.4-1.9h.1A2.43,2.43,0,0,1,223,160.63Z"
            fill={colors.splashDark}
          />
          <path
            d="M221.16,167.63a1.82,1.82,0,1,1-3.6-.6,1.82,1.82,0,0,1,2-1.61A2.06,2.06,0,0,1,221.16,167.63Z"
            fill={colors.splashDark}
          />
          <path
            d="M227.26,161h-.2a24,24,0,0,0-8.4.7c-2.6.89-3.7,1.89-5.2,2s-2.4-.81-5.2-1.5c-2.1-.61-5.9-.31-8.8.19l-.8.11a2.09,2.09,0,0,0-1.3,1.6,3.85,3.85,0,0,0,.6,1.8,2.54,2.54,0,0,1,.2.7c0,1.1.1,2.1.2,3.2a6.74,6.74,0,0,0,1.3,3.4,4.28,4.28,0,0,0,1.5.89,7.22,7.22,0,0,0,8-1.7,6.69,6.69,0,0,0,1.2-1.69c1-2,1-4.7,2.8-4.7h.2c1.3.2.9,2.6,1.6,4.39a4.91,4.91,0,0,0,1,1.9l.9.9a4.29,4.29,0,0,0,1.5.5c.7,0,1.3.1,1.9.1a6.07,6.07,0,0,0,5.6-3.9,32.55,32.55,0,0,0,1.4-5c0-.19.1-.6.3-.69a2.88,2.88,0,0,0,.9-1.81A1.48,1.48,0,0,0,227.26,161Zm-17.8,7.3a7.61,7.61,0,0,1-1.5,2.8h0a5.88,5.88,0,0,1-4.3,1.7,4,4,0,0,1-3.4-1.4,4.38,4.38,0,0,1-.5-.9,9.39,9.39,0,0,1-.5-4.7,2.34,2.34,0,0,1,.6-1.5c.7-.6,2.5-1.2,5.1-1.1a13.65,13.65,0,0,1,3.3.5,2.75,2.75,0,0,1,1.7,2.9A6.87,6.87,0,0,1,209.46,168.33Zm16.1-.8c-.3.89-1,3.2-2.3,4a3.69,3.69,0,0,1-2,.6,3.78,3.78,0,0,1-4-1.8s-.6-1.9-.6-2.1c-.2-1.31-.2-3.11.4-3.9s2.7-1.8,4.6-2.1c.2,0,.8-.11,1.4-.11a11.61,11.61,0,0,1,2.4.5,1.21,1.21,0,0,1,.8,1.2c-.1,1-.5,2.81-.7,3.71Z"
            fill={colors.splash}
          />
          <path
            d="M219.86,174.93l-6.5,2.8-1.5-10.7a1.85,1.85,0,0,1,1.5-1.2h.2a1.87,1.87,0,0,1,.3.4,13.13,13.13,0,0,1,2.1,2.7c.5,1,1.3,1.9,1.9,3C218,171.93,219.46,174.73,219.86,174.93Z"
            fill="#e18966"
          />
          <path
            d="M181.26,198.73c-3-7.7-8.6-14.7-13.2-21.5-5.3-7.7-10.3-15.81-11.5-25.11-.6-4.7,0-9.8,2.8-13.7,2.5-3.6,6.6-5.8,10.7-7.39,11.2-4.4,23.4-1.7,35.4-1.7a12.94,12.94,0,0,1-2.6.8,18.79,18.79,0,0,0-13.4,10.9c-6.6,15.8-1.3,43.8,15.1,63.3a32.51,32.51,0,0,1-17.5-.8A7.93,7.93,0,0,1,181.26,198.73Z"
            fill={colors.highlight}
          />
          <path
            d="M181.26,198.73c-3-7.7-8.6-14.7-13.2-21.5-5.3-7.7-10.3-15.81-11.5-25.11-.6-4.7,0-9.8,2.8-13.7,2.5-3.6,6.6-5.8,10.7-7.39,11.2-4.4,23.4-1.7,35.4-1.7a12.94,12.94,0,0,1-2.6.8,18.79,18.79,0,0,0-13.4,10.9c-6.6,15.8-1.3,43.8,15.1,63.3a32.51,32.51,0,0,1-17.5-.8A7.93,7.93,0,0,1,181.26,198.73Z"
            fill={colors.white}
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M189.46,131.53a26,26,0,0,0-9,15.7,51.09,51.09,0,0,0,.4,18.3,92.77,92.77,0,0,0,13.3,33.5C174.66,173,169.66,143.53,189.46,131.53Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M176.66,199.33a16.24,16.24,0,0,0,5,7.9l2-1.7c-3-2.4-7.1-7.8-5.8-10.61l-.8,1.31A3.81,3.81,0,0,0,176.66,199.33Z"
            fill={colors.highlight}
          />
          <path
            d="M176.66,199.33a16.24,16.24,0,0,0,5,7.9l2-1.7c-3-2.4-7.1-7.8-5.8-10.61l-.8,1.31A3.81,3.81,0,0,0,176.66,199.33Z"
            opacity="0.5"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M183.66,205.53l1.9-1c-5.8-4.61-5.4-8.5-5.4-8.5-.4-.7-.7-1.4-1.1-2.2a2.35,2.35,0,0,0-1.1,1.1C176.66,197.73,180.66,203.13,183.66,205.53Z"
            fill={colors.highlight}
          />
          <path
            d="M183.66,205.53l1.9-1c-5.8-4.61-5.4-8.5-5.4-8.5-.4-.7-.7-1.4-1.1-2.2a2.35,2.35,0,0,0-1.1,1.1C176.66,197.73,180.66,203.13,183.66,205.53Z"
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M190.56,221h0l3.2-1.9h0a4.76,4.76,0,0,0,1.9-4.1,14,14,0,0,0-6.4-11,4.36,4.36,0,0,0-4.4-.5h-.1l-3,1.8a5.07,5.07,0,0,1,4.3.6,14.26,14.26,0,0,1,6.4,11A5,5,0,0,1,190.56,221Z"
            fill={colors.highlight}
          />
          <path
            d="M190.56,221h0l3.2-1.9h0a4.76,4.76,0,0,0,1.9-4.1,14,14,0,0,0-6.4-11,4.36,4.36,0,0,0-4.4-.5h-.1l-3,1.8a5.07,5.07,0,0,1,4.3.6,14.26,14.26,0,0,1,6.4,11A5,5,0,0,1,190.56,221Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M187.86,220.33a5.17,5.17,0,0,1-1.3.6,4.33,4.33,0,0,0,4,.1,4.67,4.67,0,0,0,1.8-4.1,14,14,0,0,0-6.4-11,5,5,0,0,0-4.3-.6c-.1.1-.4.1-.5.2h0a4.46,4.46,0,0,0-1.7,3.6,5.8,5.8,0,0,1,1.2-1,3.39,3.39,0,0,1,3.5.4,10.54,10.54,0,0,1,4.9,8.5A3.21,3.21,0,0,1,187.86,220.33Z"
            fill={colors.highlight}
          />
          <path
            d="M187.86,220.33a5.17,5.17,0,0,1-1.3.6,4.33,4.33,0,0,0,4,.1,4.67,4.67,0,0,0,1.8-4.1,14,14,0,0,0-6.4-11,5,5,0,0,0-4.3-.6c-.1.1-.4.1-.5.2h0a4.46,4.46,0,0,0-1.7,3.6,5.8,5.8,0,0,1,1.2-1,3.39,3.39,0,0,1,3.5.4,10.54,10.54,0,0,1,4.9,8.5A3.21,3.21,0,0,1,187.86,220.33Z"
            fill={colors.white}
            opacity="0.1"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M178.16,212.23a10.68,10.68,0,0,0,4.9,8.5,4.27,4.27,0,0,0,3.5,0,3.93,3.93,0,0,0,1.3-.61,3.34,3.34,0,0,0,1.3-3.1,10.66,10.66,0,0,0-4.9-8.5,4,4,0,0,0-3.5-.4,3.91,3.91,0,0,0-1.2,1A5.86,5.86,0,0,0,178.16,212.23Z"
            fill={colors.highlight}
          />
          <path
            d="M178.16,212.23a10.68,10.68,0,0,0,4.9,8.5,4.27,4.27,0,0,0,3.5,0,3.93,3.93,0,0,0,1.3-.61,3.34,3.34,0,0,0,1.3-3.1,10.66,10.66,0,0,0-4.9-8.5,4,4,0,0,0-3.5-.4,3.91,3.91,0,0,0-1.2,1A5.86,5.86,0,0,0,178.16,212.23Z"
            fill={colors.white}
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M194.26,250.12a65.87,65.87,0,0,1,2.5-33.8l.4-1.1a23.91,23.91,0,0,0,1.7-7c0-2.5-1.3-5.4-3.8-6.1a.66.66,0,0,1-.5-.8.75.75,0,0,1,.8-.5c3.2,1,4.8,4.6,4.8,7.4a21.53,21.53,0,0,1-1.7,7.4l-.4,1.1a64.35,64.35,0,0,0-2.5,33.1h0c.1.4-.1.7-.6.8h-.1C194.66,250.73,194.36,250.53,194.26,250.12Z"
            fill={colors.white}
          />
          <path
            d="M150,217.73c-5.3,11.89,17,64.6,17,64.6a20.83,20.83,0,0,0,15.1,13.1l46.5,9.5h0a29.06,29.06,0,0,0,18.6,1.7l2.9-.7,6.1,3.1a8.82,8.82,0,0,0,9-.7l2.4-1.8.1-.1a3,3,0,0,0,.4-4.1l-3.8-4.6a7.7,7.7,0,0,0-2.2-1.8l-7.9-4.8a8.4,8.4,0,0,0-4.7-1.1L238,292a14.16,14.16,0,0,1-7-1.1l-42.8-16.6L164.06,202C164.16,202,152.36,212.63,150,217.73Z"
            fill="#f6af8c"
          />
          <path
            d="M164.16,201.93c-12.7,5-24.2,12.9-16.3,34.7l18.2,48.3a17.74,17.74,0,0,0,12.9,11l45.4,9.5c-.8-1.1-.4-11.2,7.6-15.6l-42.9-16.4-24.9-71.5Z"
            fill={colors.highlight}
          />
          <path
            d="M164.16,201.93c-12.7,5-24.2,12.9-16.3,34.7l18.2,48.3a17.74,17.74,0,0,0,12.9,11l45.4,9.5c-.8-1.1-.4-11.2,7.6-15.6l-42.9-16.4-24.9-71.5Z"
            fill={colors.white}
            opacity="0.4"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M214,303.23l10.4,2.2c-.8-1.1-.4-11.2,7.6-15.6l-11.6-4.4a17,17,0,0,0-6,8A15.67,15.67,0,0,0,214,303.23Z"
            fill={colors.white}
            opacity="0.4"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M187.76,269.83s-11.6-2.21-12.9,9.7c.8-2.5,6.4-5.9,14.3-6Z"
            opacity="0.2"
            style={{ isolation: "isolate" }}
          />
        </g>
        <g id="freepik--Screen--inject-2">
          <path
            d="M475,113.43l-149.3-86.2a3.46,3.46,0,0,0-4.3,0l-5.2,3.6,3.5,4.3v120.4c0,4.39,2.6,9.6,6,11.39l147.7,85.41.2,4.7,5.4-3.7a5.85,5.85,0,0,0,1.8-4.71V124.83C481,120.43,478.26,115.23,475,113.43Z"
            fill={colors.highlight}
          />
          <path
            d="M475,113.43l-149.3-86.2a3.46,3.46,0,0,0-4.3,0l-5.2,3.6,3.5,4.3v120.4c0,4.39,2.6,9.6,6,11.39l147.7,85.41.2,4.7,5.4-3.7a5.85,5.85,0,0,0,1.8-4.71V124.83C481,120.43,478.26,115.23,475,113.43Z"
            opacity="0.3"
            style={{ isolation: "isolate" }}
          />
          <path
            d="M470,256.62l-149.3-86.2c-3.4-1.89-6-7.1-6-11.39V35.23c0-4.4,2.6-6.5,6-4.6L470,116.83c3.4,1.9,6,7.1,6,11.4V251.92C476,256.53,473.16,258.53,470,256.62Z"
            fill={colors.highlight}
          />
          <path
            d="M470,256.62l-149.3-86.2c-3.4-1.89-6-7.1-6-11.39V35.23c0-4.4,2.6-6.5,6-4.6L470,116.83c3.4,1.9,6,7.1,6,11.4V251.92C476,256.53,473.16,258.53,470,256.62Z"
            fill={colors.highlight}
          />
          <path
            d="M470,116.93l-149.3-86.2c-3.4-1.9-6,.1-6,4.6v6l161.3,93v-6C476,123.93,473.16,118.83,470,116.93Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M318.26,31.23h0a3.23,3.23,0,0,1,1.8.6l149.3,86.1c2.9,1.7,5.4,6.5,5.4,10.4V252c0,2.4-1,4.1-2.4,4.1a3.23,3.23,0,0,1-1.8-.6l-149.3-86.1c-2.9-1.7-5.4-6.5-5.4-10.4V35.23c0-2.5.9-4,2.4-4m0-1.2c-2.2,0-3.6,1.9-3.6,5.3V159c0,4.39,2.6,9.5,6,11.39L470,256.62a4.21,4.21,0,0,0,2.4.71c2.2,0,3.6-1.9,3.6-5.3V128.33c0-4.4-2.6-9.6-6-11.4l-149.3-86.2a4.2,4.2,0,0,0-2.4-.7Z"
            fill={colors.highlight}
          />
          <path
            d="M383.86,95.43c-.2,0-.5,0-.6-.1l-56.8-32.7a1.4,1.4,0,0,1-.4-1.8,1.47,1.47,0,0,1,1.6-.5l56.8,32.9a1.47,1.47,0,0,1,.6,1.7c-.3.4-.8.7-1.2.5Z"
            fill={colors.white}
          />
          <path
            d="M463.76,141.63c-.2,0-.5-.1-.6-.1l-42-24.4a1.41,1.41,0,0,1-.5-1.8,1.31,1.31,0,0,1,1.7-.5l42.1,24.3a1.47,1.47,0,0,1,.6,1.7,1.12,1.12,0,0,1-1.3.8Z"
            fill={colors.white}
          />
          <path
            d="M412.56,112.13c-.2,0-.5-.1-.6-.1l-17.1-9.8a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l17.1,9.8a1.47,1.47,0,0,1,.6,1.7,1.56,1.56,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M383.86,175.13c-.2,0-.5,0-.6-.1l-56.8-32.91a1.4,1.4,0,0,1-.4-1.8,1.47,1.47,0,0,1,1.6-.5l56.8,32.91a1.3,1.3,0,0,1,.5,1.69A1.5,1.5,0,0,1,383.86,175.13Z"
            fill={colors.white}
          />
          <path
            d="M463.76,221.23c-.2,0-.5,0-.6-.11l-42-24.3a1.25,1.25,0,1,1,1.2-2.2l42.1,24.3a1.31,1.31,0,0,1,.5,1.7A1.41,1.41,0,0,1,463.76,221.23Z"
            fill={colors.white}
          />
          <path
            d="M412.56,191.63c-.2,0-.5,0-.6-.1l-17.1-9.8a1.48,1.48,0,0,1-.6-1.7c.2-.61,1.1-.8,1.7-.61a.1.1,0,0,1,.1.11l17.1,9.8a1.31,1.31,0,0,1,.5,1.7A1.25,1.25,0,0,1,412.56,191.63Z"
            fill={colors.white}
          />
          <path
            d="M383.86,193.83c-.2,0-.5,0-.6-.1l-56.8-32.9a1.25,1.25,0,0,1,1.2-2.2l56.8,32.7a1.47,1.47,0,0,1,.6,1.7,1.55,1.55,0,0,1-1.2.8Z"
            fill={colors.white}
          />
          <path
            d="M445.86,229.63c-.2,0-.5,0-.6-.1l-24.2-13.9a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l24.2,13.9a1.31,1.31,0,0,1,.5,1.7h0A1.25,1.25,0,0,1,445.86,229.63Z"
            fill={colors.white}
          />
          <path
            d="M412.56,210.43c-.2,0-.5-.1-.6-.1l-17.1-9.8a1.25,1.25,0,0,1,1.2-2.2h0l17.1,9.8a1.47,1.47,0,0,1,.6,1.7,1.56,1.56,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M355.56,89.13a1.07,1.07,0,0,1-.6-.2l-28.5-16.4a1.27,1.27,0,0,1-.1-1.8,1.31,1.31,0,0,1,1.3-.4l28.5,16.4a1.31,1.31,0,0,1,.5,1.7A1.72,1.72,0,0,1,355.56,89.13Z"
            fill={colors.white}
          />
          <path
            d="M437.56,136.43c-.2,0-.5-.1-.6-.1L394.86,112a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l42.1,24.3a1.47,1.47,0,0,1,.6,1.7,1.43,1.43,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M384.16,105.63c-.2,0-.5,0-.6-.1l-17.1-9.8a1.25,1.25,0,0,1,1.2-2.2h0l17.1,9.8a1.31,1.31,0,0,1,.5,1.7C385.16,105.43,384.66,105.63,384.16,105.63Z"
            fill={colors.white}
          />
          <path
            d="M383.86,115.23a1.15,1.15,0,0,1-.6-.2l-56.8-32.9a1.27,1.27,0,0,1-.1-1.8,1.33,1.33,0,0,1,1.4-.41l56.8,32.91a1.31,1.31,0,0,1,.5,1.7C384.66,115,384.26,115.33,383.86,115.23Z"
            fill={colors.white}
          />
          <path
            d="M432.86,143.63c-.2,0-.5,0-.6-.1L421,137a1.48,1.48,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l11.3,6.5a1.31,1.31,0,0,1,.5,1.7C433.86,143.43,433.36,143.63,432.86,143.63Z"
            fill={colors.white}
          />
          <path
            d="M361.06,112c-.2,0-.5-.1-.6-.1l-11.3-6.5a1.25,1.25,0,1,1,1.2-2.2l11.3,6.5a1.47,1.47,0,0,1,.6,1.7,1.07,1.07,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M412.56,131.93c-.2,0-.5-.1-.6-.1l-17.1-9.8a1.25,1.25,0,0,1,1.2-2.2h0l17.1,9.8a1.47,1.47,0,0,1,.6,1.7,2,2,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M345.26,102.93c-.2,0-.5,0-.6-.1l-18.2-10.6a1.4,1.4,0,0,1-.4-1.8,1.47,1.47,0,0,1,1.6-.5l18.2,10.6a1.21,1.21,0,0,1,.6,1.7,1.13,1.13,0,0,1-1.2.7Z"
            fill={colors.white}
          />
          <path
            d="M384.16,125.43c-.2,0-.5,0-.6-.1l-17.1-10a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l17.1,9.8a1.47,1.47,0,0,1,.6,1.7A1.16,1.16,0,0,1,384.16,125.43Z"
            fill={colors.white}
          />
          <path
            d="M383.86,135.73c-.2,0-.5,0-.6-.11l-56.8-32.9a1.4,1.4,0,0,1-.4-1.8,1.47,1.47,0,0,1,1.6-.5l56.8,32.9a1.31,1.31,0,0,1,.5,1.7A1.26,1.26,0,0,1,383.86,135.73Z"
            fill={colors.white}
          />
          <path
            d="M463.76,181.93c-.2,0-.5,0-.6-.1L421,157.53a1.31,1.31,0,0,1-.5-1.7,1.28,1.28,0,0,1,1.7-.5l42.1,24.3a1.31,1.31,0,0,1,.5,1.7A1.05,1.05,0,0,1,463.76,181.93Z"
            fill={colors.white}
          />
          <path
            d="M412.56,152.43c-.2,0-.5,0-.6-.1l-17.1-9.8a1.48,1.48,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l17.1,9.8a1.31,1.31,0,0,1,.5,1.7C413.46,152.23,413,152.33,412.56,152.43Z"
            fill={colors.white}
          />
          <path
            d="M355.56,129.33c-.2,0-.5,0-.6-.1l-28.5-16.4a1.25,1.25,0,0,1,1.2-2.2l28.5,16.4a1.47,1.47,0,0,1,.6,1.7,1.31,1.31,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M437.56,176.73c-.2,0-.5-.11-.6-.11l-42.1-24.29a1.25,1.25,0,1,1,1.2-2.2l42.1,24.3a1.47,1.47,0,0,1,.6,1.7,1.43,1.43,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M384.16,145.93c-.2,0-.5-.1-.6-.1l-17.1-9.8a1.48,1.48,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l17.1,9.8a1.31,1.31,0,0,1,.5,1.7C385.16,145.73,384.66,145.93,384.16,145.93Z"
            fill={colors.white}
          />
          <path
            d="M383.86,155.53c-.2,0-.5,0-.6-.11l-56.8-32.9a1.4,1.4,0,0,1-.4-1.8,1.47,1.47,0,0,1,1.6-.5l56.8,32.9a1.48,1.48,0,0,1,.6,1.7,1.22,1.22,0,0,1-1.2.71Z"
            fill={colors.white}
          />
          <path
            d="M432.86,183.93c-.2,0-.5-.1-.6-.1l-11.3-6.5a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l11.3,6.5a1.47,1.47,0,0,1,.6,1.7,1.31,1.31,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M361.06,152.33c-.2,0-.5-.1-.6-.1l-11.3-6.5a1.25,1.25,0,0,1,1.2-2.2l11.3,6.5a1.47,1.47,0,0,1,.6,1.7c-.3.3-.7.7-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M412.56,172.23c-.2,0-.5-.11-.6-.11l-17.1-10a1.25,1.25,0,0,1,1.2-2.2l17.1,9.81a1.46,1.46,0,0,1,.6,1.69A1.29,1.29,0,0,1,412.56,172.23Z"
            fill={colors.white}
          />
          <path
            d="M345.26,143.23c-.2,0-.5,0-.6-.11l-18.2-10.6a1.41,1.41,0,0,1-.5-1.8,1.29,1.29,0,0,1,1.8-.5l18.2,10.6a1.47,1.47,0,0,1,.6,1.7,2.08,2.08,0,0,1-1.3.71Z"
            fill={colors.white}
          />
          <path
            d="M384.16,165.73c-.2,0-.5,0-.6-.11l-17.1-9.8a1.25,1.25,0,1,1,1.2-2.2h0l17.1,9.8a1.48,1.48,0,0,1,.6,1.7,1.3,1.3,0,0,1-1.2.61Z"
            fill={colors.white}
          />
          <path
            d="M361.06,171.23c-.2,0-.5,0-.6-.11l-11.2-6.5a1.47,1.47,0,0,1-.6-1.7c.2-.6,1.1-.8,1.7-.6a.1.1,0,0,1,.1.1l11.3,6.5a1.21,1.21,0,0,1,.6,1.7,1.09,1.09,0,0,1-1.3.61Z"
            fill={colors.white}
          />
          <path
            d="M345.26,162.13c-.2,0-.5-.1-.6-.1l-18.2-10.61a1.25,1.25,0,1,1,1.2-2.19l18.2,10.6a1.47,1.47,0,0,1,.6,1.7,1.56,1.56,0,0,1-1.2.6Z"
            fill={colors.white}
          />
          <path
            d="M384.16,184.63c-.2,0-.5-.1-.6-.1l-17.1-9.8a1.25,1.25,0,1,1,1.2-2.2l17.1,9.8a1.31,1.31,0,0,1,.5,1.7C385.16,184.43,384.66,184.63,384.16,184.63Z"
            fill={colors.white}
          />
          <path
            d="M338.26,74.83v45.9c0,.69-.2,1.19-.7,1.39h0l-1.8,1v-1.6l-.3-47.19,2.3-1.2A2.79,2.79,0,0,1,338.26,74.83Z"
            fill={colors.splashDark}
          />
          <path
            d="M337.86,73.33l-2.3,1.2-55.8-32.7-1.3-.7,1.8-1.1h0a1.1,1.1,0,0,1,1.1.1l55.2,31.79A1.89,1.89,0,0,1,337.86,73.33Z"
            fill={colors.splash}
          />
          <path
            d="M336.26,75.93v45.7a1.54,1.54,0,0,1-.5,1.3h-.1a1.42,1.42,0,0,1-1.1-.1l-54.8-31.5a3.68,3.68,0,0,1-1.7-3.1V42.63a1.54,1.54,0,0,1,.5-1.3l.1-.1a1.15,1.15,0,0,1,1,.1l54.8,31.5a3.23,3.23,0,0,1,1.2,1.4A2.35,2.35,0,0,1,336.26,75.93Z"
            fill={colors.deepSpaceSparkle}
          />
          <path
            d="M278.86,41c.1,0,.4,0,.5.1l55.2,31.8a3.48,3.48,0,0,1,1.1,1.3,4.71,4.71,0,0,1,.4,1.6v45.9a1.7,1.7,0,0,1-.4,1.1h-.3c-.1,0-.4-.1-.5-.1l-55.2-31.81a4.24,4.24,0,0,1-1.6-2.9V42.23a1.67,1.67,0,0,1,.4-1.1h.1c.2,0,.3,0,.3-.1m0-.1a.76.76,0,0,0-.4.1l-.1.1a1.38,1.38,0,0,0-.5,1.3v45.8a3.82,3.82,0,0,0,1.7,3.19l55.2,31.81a1.4,1.4,0,0,0,.6.19.76.76,0,0,0,.4-.1l.1-.1a1.38,1.38,0,0,0,.5-1.3v-46a2.62,2.62,0,0,0-.5-1.7,2.76,2.76,0,0,0-1.2-1.4L279.46,41c-.1-.19-.3-.19-.6-.09Z"
            fill={colors.splash}
          />
          <text
            transform="matrix(-0.87, -0.5, 0, 1, 326.36, 105.45)"
            fontSize="30.94"
            fill="#fafafa"
            fontFamily="Montserrat-Bold, Montserrat"
            fontWeight="700"
            style={{ isolation: "isolate" }}
          >
            &lt;/&gt;
          </text>
          <path
            d="M329.36,42.23c0,1.3-1,1.8-2,1.2a4.47,4.47,0,0,1-2-3.6c0-1.3,1-1.8,2-1.2A4.47,4.47,0,0,1,329.36,42.23Z"
            fill={colors.white}
          />
          <path
            d="M337.06,47c0,1.3-1,1.8-2,1.2a4.47,4.47,0,0,1-2-3.6c0-1.3,1-1.8,2-1.2A4.3,4.3,0,0,1,337.06,47Z"
            fill={colors.white}
          />
          <path
            d="M344.66,51.73c0,1.3-1,1.8-2,1.2a4.47,4.47,0,0,1-2-3.6c0-1.3,1-1.8,2-1.2A4.44,4.44,0,0,1,344.66,51.73Z"
            fill={colors.white}
          />
        </g>
      </g>
    </svg>
  );
}

export default MFeelingProud;
