import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          Made with <span role="img">❤️</span> by {greeting.title}
        </p>
        {/* <ToggleSwitch theme={props.theme} onToggle={props.onToggle}/> */}
        <p
          className="footer-copyright-text"
          style={{ color: props.theme.secondaryText }}
        >
          <a className="no-link" href="https://storyset.com/idea">
            © Idea illustrations by Storyset
          </a>
          {/*{" & "}*/}
          {/*<a className="no-link"*/}
          {/*   href="">*/}
          {/*    Artist*/}
          {/*</a>*/}
        </p>

        {/*<p*/}
        {/*    className="footer-copyright-text"*/}
        {/*    style={{color: props.theme.secondaryText}}*/}
        {/*>*/}
        {/*    <a className="no-link"*/}
        {/*       href="">*/}
        {/*        Artist*/}
        {/*    </a>*/}
        {/*</p>*/}
      </Fade>
    </div>
  );
}
